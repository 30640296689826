import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout } from '../components'
import { parseACF } from '../utils'

import { default as Wizard } from '../components/LoanWizard'

const LoanWizard = (props) => {
	const wizardData = props.location?.state?.wizardData
	// console.log(wizardData)

	return (
		<Layout
            noHeader={true}
            noFooter={true}
        >
		    <Wizard wizardData={wizardData ? wizardData : null}/>
		</Layout>	
	)
}

export default LoanWizard